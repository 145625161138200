import request from './request'

export default (() => ({
  table({ total, page, ...params }) {
    return request.common(
      'get',
      'report/search'
    )({
      total,
      page,
      ...params
    })
  },
  language: {
    filter({ ...params }) {
      return request.common(
        'get',
        'languageMaster'
      )({
        ...params
      })
    },
    search({ type, payloadId, group }) {
      return request.common(
        'get',
        `languageMaster/detail?type=${type}&templateId=${payloadId}&group=${group}`
      )()
    },
    save(data) {
      return request.common('patch', 'languageMaster')(data)
    }
  },
  filters: {
    all() {
      return request.common('get', 'getSearchField')()
    },
    dealerCodes(countryCode) {
      return request.common(
        'get',
        'getSearchField/dealerCodes'
      )({ countryCode })
    },
    branchCodes(dealerCode) {
      return request.common('get', 'getSearchField/branchCodes')({ dealerCode })
    }
  },
  reports: {
    attachment(list) {
      return request.common('get', 'report/resource')({ ids: list })
    },
    csv(data) {
      return request.common(
        'post',
        'report/list'
      )({ params: { ids: data.ids } })
    }
  },
  column: {
    getColumnConfig() {
      return request.common('get', 'columnConfig')()
    },
    updateColumnConfig(data) {
      return request.common('put', 'columnConfig')({ columnConfigs: data })
    }
  },
  transaction: {
    delete(transactions) {
      return request.common(
        'post',
        'transaction/delete'
      )({ transactions: transactions })
    },
    getByIdAndType(data) {
      const method = data.method ? `&method=${data.method}` : ''
      return request.common(
        'get',
        `evidenceInformation/${data.id}?type=${data.type}${method}`
      )()
    }
  },
  submission: {
    comment: {
      get({ id, part, side }) {
        return request.common(
          'get',
          `submission/technicalDesignComment?submissionId=${id}&part=${part}${
            side ? `&side=${side}` : ''
          }`
        )()
      },
      patch({
        submissionId,
        part,
        side,
        technicalDesignGroup,
        technicalDesignComment
      }) {
        return request.common(
          'patch',
          `submission/technicalDesignComment`
        )(
          side
            ? {
                submissionId,
                part,
                side,
                technicalDesignGroup,
                technicalDesignComment
              }
            : {
                submissionId,
                part,
                technicalDesignGroup,
                technicalDesignComment
              }
        )
      },
      delete(id) {
        return request.common(
          'delete',
          `submission/technicalDesignComment?commentId=${id}`
        )()
      }
    },
    judgement: {
      get({ id, part, side }) {
        return request.common(
          'get',
          `submission/judgementDesign?submissionId=${id}&part=${part}${
            side ? `&side=${side}` : ''
          }`
        )()
      },
      patch({ submissionId, part, side, judgementDesign }) {
        return request.common(
          'patch',
          'submission/judgementDesign'
        )(
          side
            ? {
                submissionId,
                part,
                side,
                judgementDesign
              }
            : {
                submissionId,
                part,
                judgementDesign
              }
        )
      }
    },
    technicalDesignGroup: {
      get() {
        return request.common(
          'get',
          'submission/technicalDesignComment/master'
        )()
      }
    }
  },
  photo: {
    get({ diagnosisId, vinNo }) {
      return request.common(
        'get',
        `diagnosis/photo/configuration?diagnosisId=${diagnosisId}&vinNo=${vinNo}`
      )()
    }
  }
}))()
