import { centerStyle } from '@assets/styles/styles'
import { theme } from '@core/styles'
import styled from '@emotion/styled'

export const LineDivider = styled.div`
  height: auto;
  border: 1px solid ${theme.colors.LIGHT_GRAY};
  margin: 0 20px;
`

export const LineHorizontal = styled.div`
  border-bottom: 1px solid #dedede;
  margin-bottom: 15px;
`

export const LineDividerPopover = styled.div`
  height: auto;
  border: 1px solid ${theme.colors.LIGHT_GRAY};
  margin: 0 5px;
`

export const NoLineDivider = styled.div`
  height: auto;
  border: 0px solid ${theme.colors.LIGHT_GRAY};
  margin: 0 20px;
`

export const RowContainer = styled.div`
  display: flex;
`

export const ColContainer = styled.div`
  flex: 1;
  min-height: 400px;
  position: relative;
`

export const QuestionLabelContainer = styled.div`
  margin: 10px 0px;
`

export const QuestionLabel = styled.h4`
  font-size: 20px;
  font-weight: bold;
`

export const PaginationContainer = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const NoFileAttachmentContainer = styled.div`
  margin-bottom: 40px;
  height: 400px;
`
export const AudioContainer = styled.div`
  ${centerStyle}
  padding: 20;
  margin-top: 20px;
`

export const DetailContainer = styled.div`
  padding: 20;
  margin-top: 20px;
`
export const TopDetailContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
`
export const Title = styled.h5``

export const CarImageContainer = styled.div`
  ${centerStyle}
  padding: 20;
  height: 250px;
  position: relative;
  margin-top: 36px;
  margin-bottom: 36px;
`

export const ButtonContainer = styled.div`
  position: absolute;
`
export const ButtonBox = styled.div`
  flex-direction: column;
  ${centerStyle}
  width: 100px;
`

export const SelectedDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${theme.colors.SELECTED_BLUE};
`

export const UnselectedDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${theme.colors.LIGHT_GRAY};
`

export const SelectedLine = styled.div`
  border: 1px dashed ${theme.colors.SELECTED_BLUE};
  height: 15px;
`
export const UnselectedLine = styled.div`
  border: 1px dashed ${theme.colors.LIGHT_GRAY};
  height: 15px;
`

export const DetailItemContainer = styled.div`
  margin-bottom: 20px;
`

export const PhotoColContainer = styled.div`
  min-height: 600px;
`
export const SeatPhotoColContainer = styled.div`
  flex: 1;
  display: flex;
`

export const PhotoDetailContainer = styled.div`
  margin-top: 20px;
`

export const Description = styled.p`
  font-size: 16px;
  white-space: pre-wrap;
  width: 300px;
  overflow-wrap: break-word;
`
export const DescriptionRedText = styled.p`
  font-size: 16px;
  color: ${theme.colors.RED_RIBBON};
  margin-left: 5px;
  font-weight: bold;
`

export const WheelLabel = styled.p`
  font-weight: bold;
`

export const VehicleDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 20px 30px;
  background-color: #f3f4f6;
  border: 1px solid #fff;
  border-radius: 10px;
  article {
    display: flex;
    gap: 5px;
    .text-select-blue {
      font-weight: bold;
      color: ${theme.colors.SELECTED_BLUE};
    }
  }
`

export const CarImageButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  padding: 8px;
`

export const SeatWrinkleButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 76px;
  min-height: 212px;
`

export const SeatWrinkleButton = styled.button`
  background-color: #ffff;
  box-shadow: 2px 4px 6px 0px rgba(171, 171, 171, 0.25);
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  svg {
    margin: auto;
  }
  img {
    margin: auto;
  }
  div {
    svg {
      margin: 0px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  p {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.15px;
  }
  &:disabled {
    p {
      color: #a9a9a9;
    }
    svg {
      opacity: 0.4;
    }
  }
  &[data-enable='false'] {
    .green-check {
      display: none;
    }
    :hover {
      cursor: not-allowed;
    }
  }
  &[data-enable='true'] {
    p {
      background-color: #179f29;
      color: #ffffff;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 8px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  &[data-active='true'] {
    .green-check {
      display: none;
    }
    background-color: #0091ff;
    color: #ffffff;
    p {
      background-color: #0091ff;
      border-radius: 0px;
      color: #ffffff;
      padding: 0;
    }
  }
`

export const SeatReportImageDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`

export const SeatReportDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fbfbfb;
  border-radius: 14px;
  padding: 24px;
  gap: 8px;
`

export const SeatReportTitle = styled.h5`
  font-size: 24px;
  line-height: 24px;
  font-weight: 800;
`

export const SeatReportDetailBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const SeatReportDetailTitle = styled.p`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
`
export const SeatReportDetailDescription = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`

export const SeatReportDetailDescriptionRed = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${theme.colors.RED_RIBBON};
`

export const SeatReportDetailDescriptionGreen = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${theme.colors.GREEN_RIBBON};
`
